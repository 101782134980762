<template>
  <div class="Contact" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Formulaire de contact</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->

            <b-modal ref="sendContactMailAPIErrorModal" hide-footer title="Erreur lors l'envoi de votre demande">
              <div class="text-center">
                <p>Une erreur s'est produite lors de l'envoi de votre demande, veuillez vérifier les informations renseignées ou nous
                contacter à l'adresse directement à l'adresse
                <a href="mailto:contact@accompagnement-retraite-audiens.org?Subject=Demande%20de%20contact"> contact@accompagnement-retraite-audiens.org</a>
                si cette erreur persiste </p>
                <b-button class="mt-3" variant="outline-success" @click="hideSendContactMailAPIErrorModal">Fermer</b-button>
              </div>
            </b-modal>


            <div v-if="isContacted">
              <div class="text-center">
                <div class="mail-sent-img">
                  <b-img :src="require('@/static/images/send.svg')" style="max-width: 20%;'"
                         fluid alt="Message envoyé"  />
                </div>
                <h2 class="mt-3">
                  Votre formulaire de contact a bien été communiqué
                </h2>
                <strong>  Votre demande a bien été envoyé à Audiens. Un conseiller reviendra vers vous au plus vite.</strong>
              </div>
              <div class="row mt-2">
                <div class="col-12 text-center">
                  <b-button
                    block variant="outline-primary" class="mt-2 btn-lg"
                    @click='goToHome'>
                    Retour à l'accueil
                  </b-button>
                </div>
              </div>
            </div>
            <div v-else>
              <b-form>
                <b-form-group id="contact-first-name-group" label="Prénom" label-for="contact-first-name-input">
                  <b-form-input
                    v-model="firstName" type="text"
                    maxlength="30"
                    id="contact-first-name-input"
                    @input="$v.firstName.$touch()"
                    :state="$v.firstName.$dirty ? !$v.firstName.$error : null">
                  </b-form-input>
                </b-form-group>

                <b-form-group id="contact-last-name-group" label="Nom" label-for="contact-last-name-input">
                  <b-form-input
                    v-model="lastName" type="text"
                    maxlength="150"
                    id="contact-last-name-input"
                    @input="$v.lastName.$touch()"
                    :state="$v.lastName.$dirty ? !$v.lastName.$error : null">
                  </b-form-input>
                </b-form-group>

                <b-form-group id="contact-email-group" label="Email" label-for="contact-email-input">
                  <b-form-input
                    v-model="email" type="email"
                    maxlength="150"
                    id="contact-email-input"
                    @input="$v.email.$touch()"
                    :state="$v.email.$dirty ? !$v.email.$error : null">
                  </b-form-input>
                </b-form-group>

                <b-form-group id="contact-phone-number-group" label="Numéro de téléphone" label-for="contact-phone-number-input">
                  <b-form-input
                    type="tel" maxlength="10"
                    v-model.trim.lazy="phoneNumber"
                    id="contact-phone-number-input"
                    @input="$v.phoneNumber.$touch()"
                    :state="$v.phoneNumber.$dirty ? !$v.phoneNumber.$error : null">
                  </b-form-input>
                </b-form-group>

                <b-form-group id="contact-message-group" label="Message" label-for="contact-message-input">
                  <b-form-textarea
                    type="text" maxlength="1000"
                    v-model.trim.lazy="message"
                    id="contact-message-input"
                    @input="$v.message.$touch()"
                    :state="$v.message.$dirty ? !$v.message.$error : null">
                  </b-form-textarea>
                </b-form-group>

                <b-button
                  block :variant="sendContactMailButtonStyle" class="mt-2"
                  @click='sendContactMail'
                  :disabled="sendContactMailInProcess || $v.firstName.$invalid || $v.lastName.$invalid || $v.email.$invalid || $v.phoneNumber.$invalid || $v.message.$invalid">
                    <b-spinner small label="contact mail Spinner" v-if="sendContactMailInProcess"></b-spinner> {{ sendContactMailButtonText }}
                </b-button>
              </b-form>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength, email, integer } from 'vuelidate/lib/validators'
import { APIDisconnectedService } from '@/api/APIDisconnected'

import formTitleComponent from '@/components/formTitleComponent'

const apiDisconnectedService = new APIDisconnectedService()

export default {
  name: 'Contact',
  components: {
    formTitleComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      isContacted: false,

      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: '',

      sendContactMailButtonText: 'Envoyer votre demande',
      sendContactMailButtonStyle: 'primary',
      sendContactMailInProcess: false
    }
  },
  validations: {
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    email: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
      email
    },
    phoneNumber: {
      required,
      integer,
      minLength: minLength(10),
      maxLength: maxLength(16)
    },
    message: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(1000)
    }
  },

  methods: {
    sendContactMail () {

      this.sendContactMailInProcess = true
      this.sendContactMailButtonText = "Envoi en cours"
      this.sendContactMailButtonStyle = 'secondary'

      apiDisconnectedService.sendContactMail(
        this.firstName, this.lastName, this.email, this.phoneNumber, this.message)
      .then(() => {
        this.isContacted = true
      })
      .catch(() => {
        this.$refs.salarieCreationAPIErrorModal.show()
      })
      .finally(() => {
        this.createSalarieInProcess = false
        this.createSalarieButtonText = "Créer votre compte"
        this.createSalarieButtonStyle = 'primary'
      })
    },
    goToHome () {
      this.$router.push('/')
    },
    hideSendContactMailAPIErrorModal () {
      this.$refs.sendContactMailAPIErrorModal.hide()
    }
  }
}
</script>
